import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useAuth } from '../../ducks/auth'

export const NavigationHeader = ({ netlifyIdentity }) => {
  const onLoginClick = () => netlifyIdentity.open('login')
  const onRegisterClick = () => netlifyIdentity.open('signup')
  const onLogoutClick = () => netlifyIdentity.logout()

  const auth = useAuth()
  const isAuthenticated = !auth?.disallowed

  const loggedOutLinks = !isAuthenticated && (
    <>
      <Nav.Link as={Link} to="/" onClick={onLoginClick}>
        Login
      </Nav.Link>
      <Nav.Link as={Link} to="/" onClick={onRegisterClick}>
        Register
      </Nav.Link>
    </>
  )

  const loggedInLinks = isAuthenticated && (
    <>
      <Nav.Link as={Link} to="/">
        Home
      </Nav.Link>
      <Nav.Link as={Link} to="/logout" onClick={onLogoutClick}>
        Logout
      </Nav.Link>
    </>
  )

  return (
    <Navbar bg="dark" variant="dark" expand="md" collapseOnSelect>
      <Navbar.Brand as={Link} to="/">
        Daijoubo Con!
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {loggedInLinks}
          {loggedOutLinks}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
