import axios from 'axios'
import { createAction, handleActions } from 'redux-actions'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

export const updateAuth = createAction('auth/UPDATE_AUTH')
export const clearAuth = createAction('auth/CLEAR_AUTH')

const initialState = { disallowed: true }

export default handleActions(
  {
    [updateAuth]: (state, action) => ({
      ...state,
      ...action.payload,
      disallowed: false,
    }),
    [clearAuth]: (state, action) => initialState,
  },
  initialState
)

const selectAuth = createSelector(
  (state) => state.auth,
  (auth) => auth
)

export const useAuth = () => useSelector(selectAuth)

export const updateRemoteAuth = (user) => async (dispatch) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${user.token.access_token}`,
  }

  const config = { headers }

  const response = await axios.post(
    '/.netlify/functions/update-user',
    user,
    config
  )
  return dispatch(updateAuth(response.data))
}
