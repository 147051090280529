import React from 'react'
import { useDispatch } from 'react-redux'
import netlifyIdentity from 'netlify-identity-widget'
import { updateAuth, useAuth, clearAuth } from './ducks/auth'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { NavigationHeader } from './components/NavigationHeader'
import { Home } from './views/Home'
import { About } from './views/About'
import { useEffect } from 'react'

export const App = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  console.log(auth)
  // const { hasInitialized } = useUi()
  // const isAuthenticated = !auth.disallowed

  useEffect(() => {
    netlifyIdentity.init({ container: '#netlify-modal' })
    netlifyIdentity.on('login', (user) => {
      return dispatch(updateAuth(user)) && netlifyIdentity.close()
    })
    netlifyIdentity.on('logout', () => dispatch(clearAuth({})))
    netlifyIdentity.on('error', (err) => console.error('Error', err))
    netlifyIdentity.init({ container: '#netlify-modal' })
  })

  return (
    <Router>
      <NavigationHeader netlifyIdentity={netlifyIdentity} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
      </Switch>
    </Router>
  )
}
